import React from "react";
import { Wallet, Bridge, Arrows } from "../assets/index.js";

const data = [
  {
    id: 1,
    image: Wallet,
    title: "Connect Wallet",
    info: "Choose wallet to connect with the protocol",
  },
  {
    id: 2,
    image: Bridge,
    title: "Bridge Token",
    info: "Choose your token and your destination chain",
  },
  {
    id: 3,
    image: Arrows,
    title: "Receive Tokens",
    info: "Receive your tokens on the destination chain",
  },
];

const Features = () => {
  return (
    <div className="mt-20 flex items-center flex-col">
      <p
        className="bg-gradient-to-r from-[#CAACFF] via-[#e0cdffe5] to-[#FFFFFF] inline-block text-transparent bg-clip-text
      font-extrabold md:text-4xl text-xl  py-4 px-5"
      >
        Best-in-class DEX Aggregation protocol
      </p>

      <div className="grid md:grid-cols-3 grid-cols-1 text-white gap-8 py-5">
        {data.map((item) => {
          return (
            <div
              id={item.id}
              className="flex items-center justify-center flex-col"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-[80px] h-[80px]"
              />
              <h1 className="font-bold text-xl">{item.title}</h1>
              <p className="text-center font-normal tracking-tight leading-5 w-[200px] py-2">
                {item.info}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
