import { LiFiWidget } from "@lifi/widget";

function Wallet() {
  return (
    <LiFiWidget
      config={{
        containerStyle: {
          border: `1px solid rgb(234, 234, 234)`,
          borderRadius: "16px",
        },
      }}
      integrator="crownworld"
    />
  );
}

export default Wallet;
