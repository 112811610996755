import React from "react";
import {
  arbitrum,
  avalanche,
  bsc,
  cronos,
  ethereum,
  kcc,
  lotex,
  metis,
  nova,
  oasis,
  optimism,
  polygon,
  thundercore,
  tomochain,
  zksync,
} from "../assets/logos/index";

const chains_data = [
  {
    id: 1,
    icon: ethereum,
    name: "Ethereum",
  },
  {
    id: 2,
    icon: zksync,
    name: "zkSync",
  },
  {
    id: 3,
    icon: avalanche,
    name: "Avalanche",
  },
  {
    id: 4,
    icon: cronos,
    name: "Cronos",
  },
  {
    id: 5,
    icon: polygon,
    name: "Plolygon",
  },
  {
    id: 6,
    icon: bsc,
    name: "BSC",
  },
  {
    id: 7,
    icon: optimism,
    name: "Optimism",
  },
  {
    id: 8,
    icon: lotex,
    name: "IoTeX",
  },
  {
    id: 9,
    icon: arbitrum,
    name: "Arbitrum",
  },
  {
    id: 10,
    icon: thundercore,
    name: "ThunderCore",
  },
  {
    id: 11,
    icon: oasis,
    name: "Oasis",
  },
  {
    id: 12,
    icon: tomochain,
    name: "TomoChain",
  },

  {
    id: 13,
    icon: metis,
    name: "Metis",
  },
  {
    id: 14,
    icon: nova,
    name: "Nova",
  },
  {
    id: 15,
    icon: kcc,
    name: "KCC",
  },
];

const Chains = () => {
  return (
    <div className="flex items-center flex-col">
      <p
        className="bg-gradient-to-r from-[#CAACFF] via-[#e0cdffe5] to-[#FFFFFF] inline-block text-transparent bg-clip-text
      font-extrabold md:text-4xl text-xl  py-4 px-5 text-center "
      >
        Easily Transfer between Protocols
      </p>

      <div className="grid grid-cols-3 max-w-2xl mx-auto mb-20">
        {chains_data.map((item) => {
          return (
            <div className="flex flex-row py-2 bg-[#1a1616] rounded-md items-center justify-around m-2 px-10">
              <img
                src={item.icon}
                alt={item.name}
                className="rounded-full w-[40px] h-[40px] bg-purple-200 mx-2 "
              />
              <p className="font-semibold text-white bg-transparent">
                {item.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Chains;
