import React from "react";
import NavBar from "./components/NavBar";
import Features from "./components/Features";
import Chains from "./components/Chains";
import Wallet from "./components/Wallet";
import Discord from "./assets/Discord.svg";
import Telegram from "./assets/Telegram.svg";
import Twitter from "./assets/Twitter.svg";
import Wallet_Mobile from "./components/Wallet_Mobile";

const Social = () => {
  return (
    <>
      <a href="https://discord.com/invite/crownworld">
        <img src={Discord} alt="Discord" className="w-[30px] h-[30px]  mx-2" />
      </a>
      <a href="https://t.me/Crownworld_io">
        <img src={Telegram} alt="Telegram" className="w-[30px] h-[30px] mx-2" />
      </a>
      <a href="https://twitter.com/crownworld_io">
        <img src={Twitter} alt="Twitter" className="w-[30px] h-[30px] mx-2" />
      </a>
    </>
  );
};

const App = () => {
  return (
    <div className="bg-black h-[100vh]">
      <NavBar />
      <div className="lg:grid lg:grid-cols-12 grid-cols-1 bg-black hidden ">
        <div className="px-5 col-span-7 items-center justify-center space-y-10 hidden lg:block">
          <Features />
          <Chains />
        </div>
        <div className="col-span-5 mt-20">
          <Wallet />
        </div>
      </div>
      <div className="lg:hidden bg-black flex items-center justify-center flex-col sm:pt-20 mt-10 sm:mt-0">
        <Wallet_Mobile />
        <div className="flex flex-row items-center justify-center bg-black pb-10 -mt-8">
          <Social />
        </div>
      </div>
    </div>
  );
};

export default App;
