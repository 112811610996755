import React from "react";
import logo from "../assets/logo.svg";
import Discord from "../assets/Discord.svg";
import Telegram from "../assets/Telegram.svg";
import Twitter from "../assets/Twitter.svg";

const NavBar = () => {
  return (
    <div className="lg:px-10 px-4 py-5 flex flex-row justify-between items-center bg-black">
      {/* logo */}

      <div className="flex flex-row justify-center items-center">
        <a href="https://crownworld.io">
          <img src={logo} class="sm:h-[50px] h-[40px] pl-2" alt="Logo" />
        </a>
        <a
          class="self-center sm:text-xl text-white text-md font-bold whitespace-nowrap "
          href="https://crownworld.io"
        >
          CROWN<span className="text-[#F2B2FF]"> WORLD</span>
        </a>
      </div>

      <div className="flex flex-row space-x-4">
        <a href="https://discord.com/invite/crownworld">
          <img
            src={Discord}
            alt="Discord"
            className="w-[30px] h-[30px]  hidden lg:block"
          />
        </a>
        <a href="https://t.me/Crownworld_io">
          <img
            src={Telegram}
            alt="Telegram"
            className="w-[30px] h-[30px] hidden lg:block"
          />
        </a>
        <a href="https://twitter.com/crownworld_io">
          <img
            src={Twitter}
            alt="Twitter"
            className="w-[30px] h-[30px] hidden lg:block"
          />
        </a>
        <a
          href="https://docs.crownworld.io"
          className="text-white font-bold hover:underline"
        >
          Docs
        </a>
      </div>
    </div>
  );
};

export default NavBar;
