import { LiFiWidget } from "@lifi/widget";

function Wallet_Mobile() {
  return (
    <div className="w-[350px] ">
      <LiFiWidget
        config={{
          containerStyle: {
            borderRadius: "16px",
            border: `1px solid rgb(234, 234, 234)`,
            boxSizing: "border-box",
            width: "100%",
            minWidth: "340px",
            maxWidth: "100%",
            height: "600px",
          },
        }}
        integrator="crownworld"
      />
    </div>
  );
}

export default Wallet_Mobile;
